<template>
    <div>
        <div class="uk-card-default uk-card-hover uk-margin-small-top uk-padding-small">
            <p class="custom-title">Nombre d'interventions par ville</p>
            <apexcharts width="100%" height="100%" type="treemap" :options="options" :series="series" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'TicketNumberPerCity',
    computed: {
        ...mapState('dashboard', ['dashboardData']),
        options() {
            return {
                legend: {
                    show: false,
                },
                plotOptions: {
                    treemap: {
                        distributed: true,
                    },
                },
            }
        },
        series() {
            return [
                {
                    data: (() => {
                        const data = this.dashboardData.data.ticketNumberPerCity || []
                        return data.map((item) => ({
                            x: `${item.city} (${item.postalCode}) - ${item.country}`,
                            y: item.ticketNumber,
                        }))
                    })(),
                },
            ]
        },
    },
}
</script>

<style scoped>
.custom-title {
    min-height: 3em;
    overflow: hidden;
    line-height: 1.2em;
    margin: 0;
}
</style>
