<template>
    <div>
        <div class="uk-card-default uk-card-hover uk-margin-small-top uk-padding-small">
            <p class="custom-title">Nombre de devis</p>
            <apexcharts width="100%" height="100%" type="donut" :options="options" :series="series" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'QuotationNumber',
    computed: {
        ...mapState('dashboard', ['dashboardData']),
        options() {
            return {
                labels: ['En attente', 'Validé'],
                colors: ['#FF8D33', '#12E59D'],
            }
        },
        series() {
            return [
                this.dashboardData.data?.quotationNumber?.pending || 0,
                this.dashboardData.data?.quotationNumber?.validated || 0,
            ]
        },
    },
}
</script>

<style scoped>
.custom-title {
    min-height: 3em;
    overflow: hidden;
    line-height: 1.2em;
    margin: 0;
}
</style>
