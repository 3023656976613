<template>
    <div>
        <div class="uk-card-default uk-card-hover uk-margin-small-top uk-padding-small">
            <p class="custom-title">
                Temps moyen de réponses des
                {{
                    ['agencyAccount', 'superManagerAccount'].includes(user.userData.accountType)
                        ? 'prestataires'
                        : 'clients'
                }}
                en minutes
            </p>
            <apexcharts width="100%" height="100%" type="treemap" :options="options" :series="series" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
    name: 'AverageResponseTime',
    computed: {
        ...mapState('login', ['user']),
        ...mapState('dashboard', ['dashboardData']),
        options() {
            return {
                legend: {
                    show: false,
                },
                plotOptions: {
                    treemap: {
                        distributed: true,
                    },
                },
            }
        },
        series() {
            return [
                {
                    data: (() => {
                        const data = this.dashboardData.data.averageResponseTime || []
                        return data.map((item) => ({
                            x: item.name,
                            y: this.roundByMinutes(item.averageResponseTime),
                        }))
                    })(),
                },
            ]
        },
    },
    methods: {
        roundByMinutes(durationInSeconds) {
            return Math.round(durationInSeconds / 60)
        },
    },
}
</script>

<style scoped>
.custom-title {
    min-height: 3em;
    overflow: hidden;
    line-height: 1.2em;
    margin: 0;
}
</style>
